






































































































































import useRole from "@/use/role";
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import validator from "validator";
import { AxiosInstance } from "axios";

export interface Company {
  id: string;
  name: string;
}

export interface Role {
  text: string | number | object;
  value: string | number | object;
  disabled?: boolean;
  divider?: boolean;
  header?: string;
}

export default defineComponent({
  components: {
    MEventsSelect: () => import("@/components/molecules/m-events-select.vue"),
  },

  setup(_, { root }) {
    const form = ref<any>(null);

    const state = reactive({
      error: false as boolean | string,
      valid: true,
      isLoading: false,
      complete: false,
      showPassword: false,
      success: false,
      loading: false,
      empty: false,
      loaded: false,
      groups: [],
      groupName: "",
    });

    const { userRole, getRoleName } = useRole({ root });

    const model = reactive({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      password: "",
      passwordRepeat: "",
      role: "",
      events: [],
      group: "",
    });

    watch(
      () => model.group,
      () =>
        state.groups.filter(function(group: any) {
          if (group.id === model.group) {
            state.groupName = group.name;
          }
        })
    );

    const fetchGroups = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("user/auth/group")
        .then(({ data: { groups } }) => {
          state.empty = false;
          state.groups = groups.map((group: any, index: number) => ({
            id: index,
            name: "",
            ...group,
          }));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.groups = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchGroups);

    const rules: { [key: string]: Array<(v: any) => boolean | string> } = {
      firstName: [(v) => !!v || root.$tc("panel.user.add.enterFirstName")],
      lastName: [(v) => !!v || root.$tc("panel.user.add.enterLastName")],
      phoneNumber: [
        (v: string) =>
          !v ||
          validator.isMobilePhone(v, "any") ||
          root.$tc("layout.misc.validMobilePhone"),
      ],
      email: [
        (v) => !!v || root.$tc("panel.user.add.enterEmail"),
        (v) =>
          /.+@.+\..+/.test(v) || root.$tc("panel.user.add.enterCorrectEmail"),
      ],
      password: [
        (v) => !!v || root.$tc("panel.event.webiste.generate.enterPassword"),
        (v) =>
          v.length >= 8 ||
          root.$tc("panel.event.webiste.generate.charactersRule"),
      ],
      passwordRepeat: [
        (v) => !!v || root.$tc("panel.user.add.repeatPassword"),
        (v) => v === model.password || root.$tc("panel.user.add.matchPassword"),
      ],
      role: [
        (v) => !!v || root.$tc("panel.user.add.chooseRole"),
        (v) =>
          ["admin", "coordinator", "committee", "security"].includes(v) ||
          root.$tc("panel.user.add.chooseRole"),
      ],
    };

    const onSubmit = async () => {
      await form.value.validate();
      if (state.valid) {
        state.showPassword = false;
        state.complete = true;
      }
    };

    const goBack = () => {
      state.showPassword = false;
      state.error = false;
      state.complete = false;
    };

    const addUser = async () => {
      await form.value.validate();
      if (state.valid) {
        const axiosInstance = root.$store.getters[
          "api/getInstance"
        ] as AxiosInstance;

        state.isLoading = true;

        axiosInstance
          .post("user", {
            firstName: model.firstName,
            lastName: model.lastName,
            phoneNumber: model.phoneNumber.trim() || null,
            email: model.email,
            password: model.password,
            role: model.role,
            events: model.events.map((e: any) => e.id),
            authGroup: model.group || undefined,
          })
          .then(() => (state.success = true))
          .catch((error) => {
            if (error.response.status === 409) {
              state.error = root.$tc("panel.user.add.userEmailAlreadyExists");
            } else {
              state.error = root.$tc("panel.user.add.userCreationFailure");
            }
          })
          .finally(() => (state.isLoading = false));
      } else {
        alert(root.$tc("panel.user.add.enterValidData"));
      }
    };

    const reset = () => {
      model.firstName = "";
      model.lastName = "";
      model.phoneNumber = "";
      model.email = "";
      model.password = "";
      model.passwordRepeat = "";
      model.role = "";
      model.events = [];
      model.group = "";

      state.error = false;
      state.valid = true;
      state.isLoading = false;
      state.complete = false;
      state.showPassword = false;
      state.success = false;
    };

    return {
      form,
      state,
      model,
      rules,
      onSubmit,
      goBack,
      addUser,
      reset,
      userRole,
      getRoleName,
    };
  },
});
